import moment from './moment';
import { IValue, IValidationRules, JSTypes, ITypeElementRuntime, ComponentType, ITypeRuntime, ValidationOptions, IValidationOption } from './typeConfig';
import { getLocalizedText } from './localizationManager';
import { toNumber } from 'lodash';

const Regexes = {
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    telephone: /^[0-9 .\-();+]*$/,
    userName: /^[a-züğışçöİ.0-9]*$/i,
    fax: /^\+?[0-9]*$/,
    onlyNumber: /^[0-9]*$/,
    onlyDecimal: /^[0-9]*(\.[0-9]+)?$/,
    versionNumber: /^[0-9.-]*$/,
    webSite: /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/,
    latitude: /^(\+|-)?(?:90(?:(?:\.0{1,14})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,14})?))$/,
    longitude: /^(\+|-)?(?:180(?:(?:\.0{1,14})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,14})?))$/,
    password: /^((?=.*\d)(?=.*[a-zA-Z])[a-zA-Z0-9!^+#%$&()=?_\-*.,:;<>@~÷|]{6,20})$/,
    //TEST
    //ibanGlobal: /^[a-zA-Z]{2}[0-9]{2}\s?[a-zA-Z0-9]{4}\s?[0-9]{4}\s?[0-9]{3}([a-zA-Z0-9]\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,4}\s?[a-zA-Z0-9]{0,3})?$/,
    iban: /^(TR)\d{2}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{4}\s?\d{2}/i,
    onlyLetter: /^[a-züğışçöİ]*$/i,
    onlyLetterWithWhitespaces: /^[a-züğışçöİ ]*$/i,
    emptyGuid: /^[{(]?0[0-]*0[)}]?$/
}

class Validator {

    static validateType = (typeElement: ITypeElementRuntime, type: ITypeRuntime): string => {
        try {
            let errorText = "";
            var value = typeElement.value;
            var valRules = { ...typeElement.valRules };

            if (typeElement.typeInd == ComponentType.FILE_UPLOADER && Object.keys(value as object).some(key => key == "file" || key == "mimeType" || key == "fileName"))
                value = value["file"]

            if (valRules != null) {
                if (Validator.isNullOrEmpty(value, valRules))
                    return "";

                if (valRules.customValidator) {
                    var customValidatorResult = valRules.customValidator(typeElement, type, true);

                    if (typeof customValidatorResult == "string")
                        errorText = customValidatorResult;
                    else {
                        errorText = Validator.validate(value, customValidatorResult as IValidationRules);
                        valRules = { ...valRules, ...customValidatorResult };
                    }
                }

                if (errorText == "")
                    errorText = Validator.validate(value, valRules);
            }

            return errorText;
        } catch (error) {
            console.error(error);
            return "";
        }
    }

    static validate = (value: IValue, validateAs: IValidationRules): string => {
        if (Validator.isNullOrEmpty(value, validateAs))
            return "";
        else {
            let errorText = "";
            for (const item in ValidationOptions) {
                errorText = Validator.validateSingle(value, validateAs, ValidationOptions[item] as IValidationOption);
                if (errorText != "")
                    return errorText
            }
        }

        return "";
    }

    static validateSingle = (value: IValue, validateAs: IValidationRules, validationType: IValidationOption): string => {
        try {
            var validator: IValidationRules = validateAs;
            var valueType: JSTypes | "array" = typeof value;
            var valueStr = "";
            if (validator == null)
                return "";

            if (valueType != 'object')
                valueType = 'string';
            else if (Array.isArray(value))
                valueType = 'array'

            if (value == null)
                value = valueType === 'object' ? {} : valueType === 'array' ? [] : '';
            else
                valueStr = (value as any).toString();

            if (Validator.isNullOrEmpty(value, validateAs))
                return "";

            switch (validationType) {
                case ValidationOptions.EXACT_VALUE:
                    if (validator.exactValue != null
                        && ((!(validator.exactValue instanceof Date) && value != validator.exactValue)
                            || ((validator.exactValue instanceof Date) && !moment(validator.exactValue).isSame(moment(value as string), 'day'))))
                        return getLocalizedText("VALUE_MUST_BE_EQUAL_TO", validator.exactValue instanceof Date ? moment(validator.exactValue as Date).format('L') : (validator.exactValue as any).toString());
                    break;
                case ValidationOptions.EXACT_LENGTH:
                    if (validator.exactLength != null
                        && ((valueType === 'object' && Object.keys(value as object).length != validator.exactLength)
                            || (valueType != 'object' && valueStr.length != validator.exactLength)))
                        return getLocalizedText("TEXT_LENGTH_MUST_BE_EQUAL_TO", validator.exactLength.toString());
                    break;
                case ValidationOptions.MIN_LENGTH:
                    if (validator.minLength != null
                        && ((valueType === 'object'
                            && ((!(value instanceof Date) && Object.keys(value as object).length < validator.minLength)
                                || (value instanceof Date && isNaN(value.valueOf()))))
                            || (valueType != 'object' && valueStr.length < validator.minLength)
                        )) {
                        if ((valueType === 'object' ? Object.keys(value as object).length : valueStr.length) === 0)
                            return getLocalizedText("FIELD_CANNOT_BE_EMPTY");
                        else
                            return getLocalizedText("TEXT_MUST_BE_AT_LEAST", validator.minLength.toString());
                    }
                    break;
                case ValidationOptions.MAX_LENGTH:
                    if (validator.maxLength != null
                        && ((valueType === 'object' && Object.keys(value as object).length > validator.maxLength)
                            || (valueType != 'object' && valueStr.length > validator.maxLength)))
                        return getLocalizedText("TEXT_MUST_BE_LESS_THAN", validator.maxLength.toString());
                    break;
                case ValidationOptions.MIN_VALUE:
                    if (validator.minValue != null) {
                        var compared = Validator.compareByType(value, validator.minValue);
                        if (isNaN(compared))
                            return getLocalizedText("CONTAINS_INVALID_CHARS");
                        if (compared == -1)
                            return getLocalizedText("NUM_MUST_BE_EQUAL_OR_GREATER_THAN", validator.minValue instanceof Date ? moment(validator.minValue as Date).format('L') : (validator.minValue as any).toString());
                    }
                    break;
                case ValidationOptions.MAX_VALUE:
                    if (validator.maxValue != null) {
                        var compared = Validator.compareByType(value, validator.maxValue, true);
                        if (isNaN(compared))
                            return getLocalizedText("CONTAINS_INVALID_CHARS");
                        if (compared == 1)
                            return getLocalizedText("NUM_MUST_BE_EQUAL_OR_LESS_THAN", validator.maxValue instanceof Date ? moment(validator.maxValue as Date).format('L') : (validator.maxValue as any).toString());
                    }
                    break;
                case ValidationOptions.REGEX:
                    if (valueType === 'string' && validator.regex != null
                        && ((typeof validator.regex === 'string' && !new RegExp(validator.regex).test(valueStr))
                            || (typeof validator.regex !== 'string' && !validator.regex.test(valueStr))))
                        return validator.customErrMsg != null ? validator.customErrMsg : getLocalizedText("DOESNT_MATCH_PATTERN");
                    break;
                default:
                    return ""
            }
            return "";
        } catch (error) {
            console.error(error);
            return "";
        }
    }

    static isNullOrEmpty = (value: IValue, validateAs: IValidationRules): boolean => {
        if (value == null) {
            if (validateAs.acceptNulls)
                return true;
        }
        else if (validateAs.acceptEmptyStrings && (value as any).toString() === '')
            return true;

        return false;
    }

    static compareByType = (value: IValue, valueToCompare: IValue, acceptEmpty: boolean = false): number => {
        if (typeof value !== typeof valueToCompare)
            if (typeof valueToCompare === 'number') {
                if (acceptEmpty && value == '')
                    return 0;

                value = toNumber(value as string);

                if (isNaN(value as number))
                    return NaN;
            }
            else
                return NaN;

        if (value < valueToCompare)
            return -1;
        else if (value > valueToCompare)
            return 1;
        else
            return 0;
    }
}

export { Regexes };
export default Validator;