import React, {
  ReactElement,
  ComponentClass,
  FunctionComponentElement,
  CElement,
  CSSProperties
} from "react";
import { AxiosRequestConfig } from "axios";
import BasePage from "../components/BasePage";

import { MomentInput } from "moment";

export var SampleConfig: IPageConfig = {
  headerTitle: "",
  tabs: []
};

export var SampleType: ITypeRuntimeContainer = {};

export function pageConfigWrapper(pageConfig: IPageConfig): IPageConfig {
  return { ...SampleConfig, ...pageConfig };
}

/**
 * This is the foo function
 * @param bar This is the bar parameter
 * @returns returns a string version of bar
 */
export enum ComponentType {
  FORM_CONTROL,
  TOGGLE,
  DATE_PICKER,
  DATE_PICKER_TZ,
  TIME_PICKER,
  DROPDOWN,
  DROPDOWN_ASYNC,
  NUMERIC_INPUT,
  UPLOAD_CONTAINER,
  COLOR_PICKER,
  FILE_UPLOADER,
  MULTIPLE_SELECT,
  MULTIPLE_SELECT_ASYNC,
  PHONE_INPUT,
  EDITOR,
  TEXT_AREA,
  TAG_INPUT,
  LABEL,
  TEXT_FIELD,
  BAR_CHART,
  LINE_CHART,
  PIE_CHART,
  JSON_VIEW,
  RADIO_BUTTON,
  PROGRESS_BAR,
  MULTIPLE_PROGRESS_BAR,
  TABLE
  //TREE_VIEW_REFLECTION
}

export enum CaseStyles {
  LOWER_CASE,
  UPPER_CASE,
  FIRST_WORD_CAPITALIZED_CASE,
  ALL_WORDS_CAPITALIZED_CASE,
  ALL_WORDS_CAPITALIZED_EXCEPT_PREPOSITIONS_CASE
}

export enum SocketActionType {
  DEFAULT,
  EXCEL_IMPORT,
  ANNOUNCEMENT
}

export enum ActionType {
  View = 1,
  List,
  GetRecord,
  Insert,
  Update,
  Delete,
  Import,
  Export
}

var ActionTypes = [];
for (const key in ActionType) {
  if (ActionType.hasOwnProperty(key)) {
    const element = ActionType[key];
    if (typeof element == "string")
      ActionTypes.push({
        actionType: key,
        actionName: element
      });
  }
}
export { ActionTypes };

export enum Methods {
  LIST = "LIST",
  GET = "GET",
  INSERT = "INSERT",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  EXPORT = "EXPORT",
  IMPORT = "IMPORT",
  COMMON = "COMMON",
  DUPLICATE = "DUPLICATE"
}

export enum TablePos {
  TOP = "TOP",
  BOTTOM = "BOTTOM"
}

export enum Visibility {
  FORM = "FORM",
  TABLE = "TABLE",
  FILTER = "FILTER",
  BG_FORM = "BG_FORM",
  BG_FILTER = "BG_FILTER",
  FK_FORM = "FK_FORM",
  FK_FILTER = "FK_FILTER"
}

export enum Tables {
  POWER_GRID = "POWER_GRID",
  BS_TABLE_DEPRECATED = "BS_TABLE_DEPRECATED"
}

export enum LabelPositions {
  // TOP = 'TOP',
  LEFT_OF_INPUT = "LEFT_OF_INPUT",
  NONE = "NONE",
  ABOVE_INPUT = "ABOVE_INPUT",
  ANIMATE_ONTO_UPPER_LINE_OF_INPUT = "ANIMATE_ONTO_UPPER_LINE_OF_INPUT"
}

export interface ITypeRuntimeContainer {
  [key: string]: ITypeRuntime;
}

export interface IType {
  [key: string]: ITypeElement;
}

export type ICustomProps =
  | (React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>
    & { options?: IOptionSet[]; })
  & { [key: string]: any };

export type IComponent =
  | React.LazyExoticComponent<React.ComponentType<any>>
  | CElement<any, any>
  | ComponentClass
  | FunctionComponentElement<any>;

export type IOnValueChanged = (
  value: IValue,
  type: ITypeRuntime,
  initial: boolean,
  isFilter: boolean,
  that: BasePage
) => void;

export interface ITypeElement {
  typeInd?: ComponentType;
  defaultValue?: IValue;
  isPrimaryId?: boolean;
  typeKey?: string;
  label?: string;
  fieldName?: string;
  forceCaseTo?: CaseStyles;
  valRules?: IValidationRules;
  disabled?: boolean;
  placeholder?: string;
  visibility?: Array<Visibility>;
  optionConfig?: IOptionConfig; // RequireOnlyOne<IOptionConfig, 'referredType' | 'filterBy'>;
  filterOptionConfig?: IFilterOptionConfig;
  toggleConfig?: IToggleConfig;
  customProps?: ICustomProps;
  filterProps?: ICustomProps;
  // showLabel?: boolean;
  labelProps?: ILabelProps;
  columnSize?: IGroupColumnSizeConfig;
  group?: IGroup;
  showGroupInFilter?: boolean;
  /**
   * @summary Masks the input according to pattern.
   * @description Examples
   * - 1099911111, "(99) 9999-9999" -> (10) 9991-1111
   * - 12122000, "99/99/9999" -> 12/12/2000
   * - 99911111101, "999.999.999-99" -> 999.111.111-01
   * - 'ABC1234', "AAA-9999" -> ABC-1234
   * - '9BGRD08X04G117974', "SS.SS.SSSSS.S.S.SSSSSS" -> 9B.GR.D08X0.4.G.117974
   */
  maskPattern?: string | { pattern: string; placeholder?: string };
  onValueChanged?: IOnValueChanged;
  tableDataFormatter?: ITableDataFormatter;
  valueSerializer?: ITypeElementValueSerializer;
  valueDeserializer?: ITypeElementValueDeserializer;
  exportSerializer?: IExportSerializer;
  exportDeserializer?: IExportDeserializer;
  exportConfig?: IExportConfig & IExportItemConfig;
  filterValRules?: IValidationRules;
  tableConfig?: ITableComponentConfig,
  tableColumnDef?: ICustomProps,
  //asd?: this['typeInd']> 0 ? string : number;
}

export type IGroupFull = IGroup /* & IGroupRuntime */;

export type IGroup = {
  name?: string;
  title?: string;
  titleStyle?: CSSProperties;
  headerStyle?: CSSProperties;
  headerButtonStyle?: CSSProperties;
  defaultOpen?: boolean;
  columnSize?: IColumnSizeConfig;
  bodyStyle?: CSSProperties;
  rootStyle?: CSSProperties;
  isCollapsible?: boolean;
};

// export type IGroupRuntime = {
//     groupId?: string;
// }

export type ITableTypeProps = {
  [P in Tables]: ITableTypeItemProps;
};

export interface ITableTypeItemProps {
  component: IComponent;
  createCustomProperties: (
    type: ITypeRuntime,
    columns: Array<any>,
    //data: Array<any>,
    idKeyFull: string
  ) => object;
  createColumnDefinition: (type: ITypeElementRuntime) => object;
  columnDefinitionProp: string;
  dataProp: string;
  paginationProp: string;
  customProps?: object;
}

export type ITableDataFormatter = (cellVal: any, row: any) => ITableDataFormatterResult;

export type ITableDataFormatterResult = string | HTMLElement | ReactElement<any>;

export type IComponentTypeProps = {
  [P in ComponentType]: IComponentTypeItemProps;
};

export interface IComponentTypeItemProps {
  eventFunc: (
    type: ITypeRuntime,
    typeKey: string,
    key: string,
    cb?: Function
  ) => Function;
  eventName: string;
  valueProp: string;
  defaultValue: IValue;
  placeholderProp: string;
  disabledProp?: string;
  defaultPlaceholder?: string;
  isDropdown?: boolean;
  asyncRequest?: boolean;
  component: IComponent;
  errorMessageStyle?: CSSProperties;
  requiredIndicatorStyle?: CSSProperties;
  valueSerializer?: IValueSerializer;
  valueDeserializer?: IValueDeserializer;
  columnSize?: IColumnSizeConfig;
  labelProps?: ILabelProps;
  exportSerializer?: IExportSerializer;
  exportDeserializer?: IExportDeserializer;
}

export type IValueSerializer = (typeElement: ITypeElementRuntime) => Object;

export type IValueDeserializer = (
  typeElement: ITypeElementRuntime,
  value: IValue
) => IValue;

export type ITypeElementValueSerializer = (
  typeElement: ITypeElementRuntime,
  valueSerializer?: IValueSerializer
) => Object;

export type ITypeElementValueDeserializer = (
  typeElement: ITypeElementRuntime,
  value: IValue,
  valueDeserializer?: IValueDeserializer
) => IValue;

export type IExportSerializer = (
  typeElement: ITypeElementRuntime,
  value: IValue,
  valueSerializer?: IValueSerializer
) => IValue;

export type IExportDeserializer = (
  typeElement: ITypeElementRuntime,
  value: IValue,
  valueDeserializer?: IValueDeserializer
) => IValue;

export type ColumnSizes = "xs" | "sm" | "md" | "lg" | "xl";

export type IGroupColumnSizeConfig = IColumnSizeConfig | { group: IColumnSizeConfig };

export type IColumnSizeConfig =
  {
    [P in ColumnSizes]?: number;
  }
  | { all: number };

// interface IFooBar extends IFoo, IBar {}
//type IFooBar = IFoo & IBar;

export type ITypeRuntime = {
  [key: string]: ITypeElementRuntime;
};

export interface ITypeElementRuntime extends ITypeElement {
  key?: string;
  value?: IValue;
  originalValue?: IValue;
  isValid?: boolean;
  isRequired?: boolean;
  errorMessage?: string;
  setValue?: (obj: IValue, cb?) => void;
  setState?: (typeElement: ITypeElementRuntime, cb?: Function) => void;
  updateState?: (cb?: Function) => void;
  loadOptions?: (
    inputText: string,
    cb?: Function
  ) => Promise<Array<IOptionSet>>;
  validate?: () => boolean;
  group?: IGroupFull;
  exportConfig?: IExportConfig;
  getFilter?: () => ITypeElementRuntime;
}

export type IValue =
  | string
  | number
  | boolean
  | Date
  | Array<string>
  | IFileContent
  | IOptionSet
  | Array<IOptionSet>
  | MomentInput
  | Object;

export type IComponentProps = {
  typeInd?: ComponentType;
  title?: string;
  isRequired?: boolean;
  style?: any;
  typeElement?: ITypeElementRuntime;
};

export type IComponentPropsRuntime = IComponentProps & {
  bindField?: ITypeElementRuntime;
  fullType: ITypeRuntime;
  onValueChanged?: IOnValueChanged;
};

export type JSTypes =
  | "string"
  | "number"
  | "bigint"
  | "boolean"
  | "symbol"
  | "undefined"
  | "object"
  | "function";

export interface IValidationRules {
  minLength?: number;
  maxLength?: number;
  exactLength?: number;
  minValue?: IValue;
  maxValue?: IValue;
  exactValue?: IValue;
  regex?: RegExp;
  forceRegex?: boolean;
  acceptNulls?: boolean;
  acceptEmptyStrings?: boolean;
  customErrMsg?: string;
  customValidator?: (
    typeElement: ITypeElementRuntime,
    type: ITypeRuntime,
    showValidations: boolean
  ) => string | IValidationRules;
}

export interface IToggleConfig {
  ON: string | number | boolean;
  OFF: string | number | boolean;
}

export interface IOptionConfig {
  referredType?: IType;
  listUrl: string;
  fetchRecord?: boolean;
  getValue: (item?: any) => string;
  getLabel: (item?: any) => string;
  filterBy?: (type: ITypeRuntime, inputText: string) => object;
}

export interface IFilterOptionConfig {
  referredType?: IType;
  listUrl?: string;
  getValue?: (item?: any) => string;
  getLabel?: (item?: any) => string;
  filterBy?: (type: ITypeRuntime, inputText: string) => object;
}

export interface IFileContent {
  file: string;
  fileName: string;
  mimeType: string;
}

export interface IOptionSet {
  label: string;
  value: string;
  record: any;
}

export type Class = { new(...args: any[]): any };

export interface IPageConfig {
  headerTitle: string;
  tablePosition?: TablePos;
  tabs?: Array<ITabConfig>;
  customEvents?: ICustomEvent;
}

export type TypeEventMain = [ITypeElementRuntime, "value" | "originalValue"];
export type TypeEvent = [
  ITypeElementRuntime,
  TypeEventNames | Array<TypeEventNames>
];
export type TypeEventNames = "value" | "originalValue" | "defaultValue";

export interface ICustomRenderer {
  override: boolean;
  renderPosition: "top" | "bottom";
  columnSize?: IColumnSizeConfig;
  customProps?: object;
  componentClass: ComponentClass;
}

export interface ITabConfig {
  title: string;
  showAddButtonOnTab?: boolean;
  type: IType;
  resourceCode: string;
  editOnModal: boolean;
  collapseOnEdit?: boolean;
  clearOnSave?: boolean;
  addButtonText?: string;
  saveButtonText?: string;
  deleteButtonText?: string;
  filterButtonText?: string;
  clearButtonText?: string;
  exportFileName?: string;
  list?: IRequestMethod;
  get?: IRequestMethod;
  insert?: IRequestMethod;
  update?: IRequestMethod;
  delete?: IRequestMethod;
  import?: IRequestMethod;
  allowedMethods: Array<Methods>;
  customRenderers?: {
    formRenderers?: ICustomRenderer[];
  };
  tableConfig?: ITableConfig;
  refreshOnSave?: boolean;
  refreshOnDelete?: boolean;
}

export interface ITableConfig {
  type?: Tables;
  customProps?: ICustomProps;
  forceGetRecordFromTable?: boolean;
  actionColumnRenderer?: ({ row: any, gridRef: ITableApiBase, basePageRef: BasePage, ...tableProps }) => IComponent;
}

export abstract class ITableApiBase {
  abstract onRefresh(): void;
  abstract onShown(): void;
  abstract getRows(): any[];
  abstract getSelectedRows(): any[];
  abstract getInternalApi(): any;
}

export type ICustomEvent = (
  ...types: ITypeRuntime[]
) => ICustomEventResult;

export type ITableCustomEvent = (externalType: ITypeRuntime, internalType: ITypeRuntime) => ICustomEventResult;

export type ICustomEventResult = Array<[TypeEventMain, TypeEvent[]]>

export interface IRequestMethod {
  url: string;
  requestParams?: (params: any, typeParams: any) => Object;
  config?: AxiosRequestConfig;
}

export type IDefaultRequestParams = {
  [P in Methods]?: (params: any) => object;
};

export type IDefaultRequestMethods = {
  [P in Methods]?: HttpMethods;
};

export enum HttpMethods {
  GET = "GET",
  HEAD = "HEAD",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH"
}
///HELPER

export type RequireOnlyOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> &
    Partial<Record<Exclude<Keys, K>, undefined>>;
  }[Keys];

export enum TenantType {
  SystemOwner = 1,
  IndividualTenant,
  CorporateTenant,
  IndividualDealerTenant,
  CorporateDealerTenant,
  IndividualCustomerTenant,
  CorporateCustomerTenant
};

export enum AuthTemplateType {
  Template = 1,
  Role
};

export interface IExportConfig {
  excelConfig?: IExportItemConfig;
}

export interface IExportItemConfig {
  importable?: boolean;
  exportable?: boolean;
  order?: number;
  label?: string;
}

export type ILabelProps = {
  position?: LabelPositions;
} & ICustomProps

export type ICustomParameterFormat = {
  [key: string]: string;
}

export interface ITableComponentConfig {
  referredPageConfig: IPageConfig;
  //pageOrder?:number;
  referredTabIndex: number;
  customEvents?: ITableCustomEvent;
  forceGetRecordFromPageConfig?: boolean;
  hideRefreshButton?: boolean;
  hideFilters?: boolean;
  filterBy?: (params: any, type: ITypeRuntime) => object;
  refreshButtonProps?: ICustomProps & { refreshButtonText?: string };
}

export enum ValidationOptions {
  MIN_LENGTH = "MIN_LENGTH",
  MAX_LENGTH = "MAX_LENGTH",
  EXACT_LENGTH = "EXACT_LENGTH",
  MIN_VALUE = "MIN_VALUE",
  MAX_VALUE = "MAX_VALUE",
  EXACT_VALUE = "EXACT_VALUE",
  REGEX = "REGEX"
}

export type IValidationOption = keyof typeof ValidationOptions;

export type IPrimaryIdEmptinessComparers = {
  [key: string]: IPrimaryIdEmptinessComparer;
}

export type IPrimaryIdEmptinessComparer = {
  (id: IValue, idStr: string): boolean;
}

export enum ParameterFormatterType {
  ColorText = 1,
  Badge,
  Icon,
  BadgeAndIcon,
  BadgeAndIconWithColor
};