import _ from "lodash";
import { getPreferredLanguage } from "./localizationManager";
import { LabelPositions, ComponentType, Tables, IToggleConfig, IExportConfig, ILabelProps, ICustomParameterFormat, IPrimaryIdEmptinessComparers } from "./typeConfig";
import { Regexes } from "./validator";

export const IdentityURL = process.env.REACT_APP_IDENTITY_URL;
export const AdminURL = process.env.REACT_APP_ADMIN_URL;
export const ApiURL = process.env.REACT_APP_API_URL;
export const ApiURL_2 = process.env.REACT_APP_API2_URL;
export const ApiURL_3 = process.env.REACT_APP_API3_URL;
export const KibanaURL = process.env.REACT_APP_KIBANA_URL;
export const SchedulerURL = process.env.REACT_APP_SCHEDULER_URL;
export const ECommerceURL = process.env.REACT_APP_ECOMMERCE_URL;
export const SERVICE_DESK_APP_FOOTER_DATA = process.env.REACT_APP_SERVICE_DESK_APP_FOOTER_DATA;
export const SERVICE_DESK_APP_FOOTER_LINK = process.env.REACT_APP_SERVICE_DESK_APP_FOOTER_LINK;
export const SERVICE_DESK_APP_FOOTER_COPYRIGHT = process.env.REACT_APP_SERVICE_DESK_APP_FOOTER_COPYRIGHT;
export const HEADER_LOGO_PATH = process.env.REACT_APP_HEADER_LOGO_PATH;
export const HEADER_LOGO_MINIMIZED_PATH = process.env.REACT_APP_HEADER_LOGO_MINIMIZED_PATH;

export const DEFAULT_CACHE_EXPIRATION_MIN = 5;
export const DEFAULT_CACHE_LOCK_TIMEOUT_MS = 5000;
export const DEFAULT_CACHE_STORAGE = "local";

export const DEFAULT_LABEL_POSITION = LabelPositions.LEFT_OF_INPUT;
export const DEFAULT_TABLE_CHOICE = Tables.POWER_GRID;
export const DEFAULT_COMPONENT_TYPE = ComponentType.FORM_CONTROL;
export const DEFAULT_GRID_BLOCK_SIZE = 100;

export const IsRTL = _.get(getPreferredLanguage(), 'isRTL', false);

export const ERROR_MESSAGE_COLUMN_NAME = "errorMessage";

export const DEFAULT_TOGGLE_CONFIG: IToggleConfig = {
    ON: 1,
    OFF: 0
}

export const DEFAULT_EXPORT_CONFIG: IExportConfig = {
    excelConfig: {
        exportable: true,
        importable: true,
        label: null,
        order: 999,
    }
}

export const CHANGE_PASSWORD_URL = '/changePassword';
export const PROFILE_URL = '/profile';

export const DEFAULT_LANGUAGE_CODE = "en";

export const DEFAULT_LABEL_PROPS: ILabelProps = {
    position: DEFAULT_LABEL_POSITION,
}

//#region Enums

export enum UserStatus {
    DELETED = -1,
    WAITING_FOR_APPROVAL,
    STILL_WORKS,
    QUITTED,
    WAITING_FOR_VERIFICATION
}

export enum ThemeColors {
    SUCCESS = "#4DBD74",
    WARNING = "#F9B115",
    DANGER = "#E6471E",
    PRIMARY = "#20a8d8",
    SECONDARY = "#6c757d",
}

//#region ServiceDesk

export enum ChannelType {
    Email = 1,
    WebForm,
    Phone,
    //LiveChat TODO: ??
}

export enum ConversationType {
    Email = 1,
    SystemNotification,
    Notes
}

export enum CustomerContractStatus {
    Passive = -1,
    Active = 1,
    Completed
}

export enum CustomerContractType {
    Maintenance = 1,
    Support,
    Warranty,
    Lease
}

export enum ImpactType {
    Low = 1,
    AffectsUser = 2,
    AffectsDepartment = 3,
    AffectsBusiness = 4
}

export enum LevelType {
    Tier0 = 0,
    Tier1,
    Tier2,
    Tier3,
    Tier4
}

export enum PriorityType {
    Low = 1,
    Normal,
    Medium,
    High,
    Critical
}

export enum TaskPriorityType {
    Low = 1,
    Normal,
    Medium,
    High,
    Critical
}

export enum RequestStatus {
    Open = 1,
    InProgress,
    WaitingForUpdate,
    ApprovalPending,
    Rejected,
    Cancelled,
    Resolved,
    Closed
}

export enum TaskStatus {
    Open = 1,
    InProgress = 2,
    WaitingForUpdate = 3,
    ApprovalPending = 4,
    Closed = 5
}

export enum ArticleStatus {
    Deleted = -1,
    Draft = 1,
    WaitingForApproval = 2,
    Released = 3
}

export enum TaskType {
    Task = 1,
    Meeting = 2,
    Planning = 3,
    Testing = 4,
    Release = 5
}

export enum RequestType {
    Incident = 1,
    Problem,
}

export enum UserType {
    Admin = 1,
    Technician = 2,
    CustomerRequesterUser = 3,
}

export enum CustomerTenantType {
    IndividualCustomer = 6,
    CorporateCustomer = 7
}

export enum EmploymentType {
    Internal = 1,
    Outsource = 2
}

export enum GroupType {
    SupportGroup = 1,
}

export enum UserLikeType {
    Like = 1,
    Dislike = 2
}


export const REQUEST_CLOSED_STATUS = [
    RequestStatus.Cancelled,
    RequestStatus.Rejected,
    RequestStatus.Resolved,
    RequestStatus.Closed,
]

//#endregion ServiceDesk

//#endregion Enums

//#region ServiceDesk CustomFormatter
export const REQUEST_TASK_PRIORITY_CUSTOM_FORMAT: ICustomParameterFormat = {
    "1": ThemeColors.SUCCESS,
    "2": ThemeColors.WARNING,
    "3": ThemeColors.WARNING,
    "4": ThemeColors.DANGER,
    "5": ThemeColors.DANGER
}

export const REQUEST_TASK_STATUS_CUSTOM_FORMAT: ICustomParameterFormat = {
    "1": ThemeColors.SECONDARY,
    "2": ThemeColors.WARNING,
    "3": ThemeColors.DANGER,
    "4": ThemeColors.PRIMARY,
    "5": ThemeColors.SUCCESS
}

export const REQUEST_PRIORITY_CUSTOM_FORMAT: ICustomParameterFormat = {
    "1": ThemeColors.SUCCESS,
    "2": ThemeColors.WARNING,
    "3": ThemeColors.WARNING,
    "4": ThemeColors.DANGER,
    "5": ThemeColors.DANGER
}

export const REQUEST_STATUS_CUSTOM_FORMAT: ICustomParameterFormat = {
    "1": ThemeColors.WARNING,
    "2": ThemeColors.PRIMARY,
    "3": ThemeColors.WARNING,
    "4": ThemeColors.PRIMARY,
    "5": ThemeColors.DANGER,
    "6": ThemeColors.WARNING,
    "7": ThemeColors.SUCCESS,
    "8": ThemeColors.WARNING
}

export const REQUEST_IMPACT_CUSTOM_FORMAT: ICustomParameterFormat = {
    "1": ThemeColors.SUCCESS,
    "2": ThemeColors.SECONDARY,
    "3": ThemeColors.WARNING,
    "4": ThemeColors.DANGER,
}

export const REQUEST_CHANNEL_CUSTOM_FORMAT: ICustomParameterFormat = {
    "1": "fa fa-envelope",
    "2": "fa fa-file",
    "3": "fa fa-phone-square"
}

export const CUSTOMER_CONTRACT_TYPE_CUSTOM_FORMAT: ICustomParameterFormat = {
    "1": "fa fa-wrench",
    "2": "fa fa-ticket",
    "3": "fa fa-shield",
    "4": "fa fa-handshake-o"
}

export const CUSTOMER_CONTRACT_STATUS_CUSTOM_FORMAT: ICustomParameterFormat = {
    "-1": ThemeColors.SECONDARY,    
    "1": ThemeColors.SUCCESS,
    "2": ThemeColors.PRIMARY,
}
export const REQUEST_TASK_TYPE_CUSTOM_FORMAT: ICustomParameterFormat = {
    "1": ThemeColors.SECONDARY,
    "2": ThemeColors.WARNING,
    "3": ThemeColors.DANGER,
    "4": ThemeColors.PRIMARY,
    "5": ThemeColors.SUCCESS
}

export const IS_PUBLISH_SELF_SERVICE_PORTAL_CUSTOM_FORMAT: ICustomParameterFormat = {
    'false': `fa fa-users,${ThemeColors.SECONDARY}`,
    "true": `fa fa-globe,${ThemeColors.SECONDARY}`
}

export const USER_LIKE_CUSTOM_FORMAT: ICustomParameterFormat = {
    "0": `fa fa-thumbs-o-up,${ThemeColors.SUCCESS}`,
}

export const USER_DISLIKE_CUSTOM_FORMAT: ICustomParameterFormat = {
    "0": `fa fa-thumbs-o-down,#f36e6e`
}

export const USER_LIKE_TYPE_CUSTOM_FORMAT: ICustomParameterFormat = {
    "1": `fa fa-thumbs-o-up,${ThemeColors.SUCCESS}`,
    "2": `fa fa-thumbs-o-down,${ThemeColors.DANGER}`
}

export const ARTICLE_STATUS_CUSTOM_FORMAT: ICustomParameterFormat = {
    "-1": ThemeColors.DANGER,
    "1": ThemeColors.SECONDARY,
    "2": ThemeColors.WARNING,
    "3": ThemeColors.SUCCESS,
}

//#endregion

export const WORKING_STATUS_CUSTOM_FORMAT: ICustomParameterFormat = {
    "-1": ThemeColors.DANGER,
    "0": ThemeColors.WARNING,
    "1": ThemeColors.SUCCESS,
    "2": ThemeColors.DANGER,
    "3": ThemeColors.WARNING
}

export const STATUS_CUSTOM_FORMAT: ICustomParameterFormat = {
    "-1": ThemeColors.DANGER,
    "1": ThemeColors.SUCCESS,
}

export const TOGGLE_CUSTOM_FORMAT: ICustomParameterFormat = {
    "true": ThemeColors.SUCCESS,
}

export const PRIMARY_ID_EMPTINESS_COMPARERS: IPrimaryIdEmptinessComparers = {
    FALSY: (id, idStr) => !id,
    EMPTY_GUID: (id, idStr) => Regexes.emptyGuid.test(idStr)
}

export default {
    AdminURL, IdentityURL, ApiURL, ApiURL_2, ApiURL_3, KibanaURL, SchedulerURL, ECommerceURL,
    DEFAULT_LABEL_POSITION, DEFAULT_CACHE_EXPIRATION_MIN, DEFAULT_CACHE_LOCK_TIMEOUT_MS,
    DEFAULT_CACHE_STORAGE, DEFAULT_TABLE_CHOICE, DEFAULT_COMPONENT_TYPE, IsRTL,
    DEFAULT_GRID_BLOCK_SIZE, DEFAULT_TOGGLE_CONFIG, DEFAULT_EXPORT_CONFIG, CHANGE_PASSWORD_URL, UserStatus, DEFAULT_LABEL_PROPS,
    WORKING_STATUS_CUSTOM_FORMAT, STATUS_CUSTOM_FORMAT, TOGGLE_CUSTOM_FORMAT, ERROR_MESSAGE_COLUMN_NAME,
    PRIMARY_ID_EMPTINESS_COMPARERS
}