import { Dictionary } from "./dictionary";
import { cacheGet, cacheSet, cacheDelete } from "./cacheManager";
import { Translations } from "./translations";
import { allLanguages } from "./languages";
import _ from "lodash";

String.prototype.format = function (this: string, params: string[]) {
    var formatted = this;
    for (var arg in arguments) {
        formatted = formatted.replace("{" + arg + "}", arguments[arg]);
    }
    return formatted;
};

String.prototype.isEmptyString = function (this: string) {
    return this === null || this === undefined || this.length === 0;
}

function getLocalizedText(keyCode: string, ...params: string[]): string {
    return getLocalizedTextFull(keyCode, undefined, null, ...params);
}

function getLocalizedTextWithValue(keyCode: string, value: number | string, ...params: string[]): string {
    return getLocalizedTextFull(keyCode, undefined, value, ...params);
}

function getLocalizedTextWithLocale(keyCode: string, prefferedLocale: string, ...params: string[]): string {
    return getLocalizedTextFull(keyCode, prefferedLocale, null, ...params);
}

function getLocalizedTextFull(keyCode: string, prefferedLocale = getCurrentLocale(), value: number | string, ...params: string[]): string {
    //console.debug('Translation', keyCode, prefferedLocale);
    if (keyCode == null)
        return "";
    //throw Error(`getLocalizedText function's keyCode parameter cannot be null !`);

    keyCode = keyCode.hasOwnProperty("keyCode") ? keyCode["keyCode"] : keyCode.replace(/_filter$/, '');
    //keyCode = keyCode.toUpperCase();

    prefferedLocale = prefferedLocale ? prefferedLocale.toUpperCase() : getCurrentLocale().toUpperCase();
    var translations = getTranslations();

    if (translations.hasOwnProperty(keyCode)) {
        var translationsArray = translations[keyCode].translations;
        if (translationsArray == null || translationsArray.length == 0 || translationsArray[0] == null || translationsArray[0].languages == null)
            console.warn(`${keyCode} has no translations property or empty !`);
        else {
            if (value == null) {
                //TODO CHECK
                //-
                if (translationsArray[0] == null || translationsArray[0].languages == null) {
                    console.error(`asdasd !`, translationsArray);
                }
                //-
                let languageItem = translationsArray[0].languages;
                if (languageItem && languageItem.hasOwnProperty(prefferedLocale))
                    return languageItem[prefferedLocale].format(params);
                else if (prefferedLocale != "EN" && languageItem && languageItem.hasOwnProperty("EN")) {
                    console.warn(`Translation (${keyCode}) could not find for ${prefferedLocale} fallback to EN !`);
                    return languageItem["EN"].format(params);
                }
                else
                    console.warn(`Translation (${keyCode}) could not find for ${prefferedLocale} !`);
            }
            else {
                var translationsArrayItem = translationsArray.find(x => x.value == value);
                if (translationsArrayItem == null) {
                    console.warn(`${keyCode} has no specified value ! (${value})`);
                    keyCode = '';
                } else {
                    let languageItem = translationsArrayItem.languages;
                    if (languageItem && languageItem.hasOwnProperty(prefferedLocale))
                        return languageItem[prefferedLocale].format(params);
                    else if (prefferedLocale != "EN" && languageItem && languageItem.hasOwnProperty("EN")) {
                        console.warn(`Translation (${keyCode}) could not find for ${prefferedLocale} fallback to EN !`);
                        return languageItem["EN"].format(params);
                    }
                    else
                        console.warn(`Translation (${keyCode}) could not find for ${prefferedLocale} !`);
                }
            }
        }
    }
    // else
    //     console.warn(`${keyCode} does not exists in translations !`);

    return keyCode || "";
};

function getPreferredLanguage() {
    var preferredLang = cacheGet('preferredLang');
    return preferredLang && typeof preferredLang == "object" ? preferredLang : null;
}

function setPreferredLanguage(lang) {
    var preferedLang = getPreferredLanguage();
    if (preferedLang && preferedLang.code == lang.code)
        return;

    cacheSet('preferredLang', lang);
    window.location.reload();
}

function clearPreferredLanguage() {
    if (getPreferredLanguage() == null)
        return;

    cacheDelete('preferredLang');
    window.location.reload();
}

function getCurrentLocale(): string {
    var preferedLang = getPreferredLanguage();
    return preferedLang ? preferedLang.code : (navigator.language || (navigator as any).userLanguage).split('-')[0];
};

function getPreferredLocales(): string[] {
    var languages = navigator.languages;
    if (languages)
        return _.uniq(languages.map(lang => lang.split('-')[0]))
    else
        return [getCurrentLocale()];
};

function getCurrentLocaleFlag(): string {
    var locale = getCurrentLocale();
    var lang = allLanguages.find(lang => lang.code == locale);

    return lang ? lang.flagCode : null;
}

function getTranslations(): Dictionary {
    var cachedTranslations = cacheGet('translations');
    if (cachedTranslations)
        return cachedTranslations;
    else
        return Translations;
};

function convertListToTranslation(paramList: Array<any>): Dictionary {
    return _(paramList)
        .groupBy('keyCode')
        .mapValues((labelGroup, keyCode) => ({
            "keyCode": keyCode,
            "translations": labelGroup.map(label => ({
                "value": label.value,
                "languages": _(label.translations)
                    .pickBy(label => !!label)
                    .mapKeys((_, key) => key.toUpperCase())
                    .value()
            }))
        }))
        .value();
}

// var geoInfo = cacheGet("geoInfo");

// function getCountryCode(): string {
//     return ((geoInfo ? geoInfo.country : null) || "us").toLowerCase();
// };

// if (geoInfo == null) {
//     $.ajax( {
//         url: '//freegeoip.net/json/',
//         type: 'POST',
//         dataType: 'jsonp',
//         success: function(location) {
//             console.log(location);
//         }
//       } );  
// }

// axios.get("https://ipapi.co/json/")
//     .then(response => { console.log(response); cacheSet("geoInfo", geoInfo = response, 600) })
//     .catch(err=>console.error(err));

export { getTranslations, getCurrentLocale, getCurrentLocaleFlag, getPreferredLocales, getLocalizedText, getLocalizedTextWithValue, getLocalizedTextWithLocale, convertListToTranslation, getPreferredLanguage, setPreferredLanguage, clearPreferredLanguage }