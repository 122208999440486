import React from 'react';
import _ from 'lodash';
import { getLocalizedText } from './common/localizationManager';

//const Notifications = React.lazy(() => import('./views/Routes/Management/Notifications/Notifications'));
//const Scheduler = React.lazy(() => import('./views/Routes/Management/Scheduler/Scheduler'));
const AuthTemplates = React.lazy(() => import('./views/Routes/Management/AuthTemplates/AuthTemplates'));
const Dashboard = React.lazy(() => import('./views/Routes/Management/Dashboard/Dashboard'));
const PartnerDashboard = React.lazy(() => import('./views/Routes/PartnerDashboard/PartnerDashboard'));
const Tenant = React.lazy(() => import('./views/Routes/Management/Tenant/Tenant'));
const Company_Mng = React.lazy(() => import('./views/Routes/Management/Companies/Company'));
const Users_Mng = React.lazy(() => import('./views/Routes/Management/Users/Users'));
const ResourceDefinition_Mng = React.lazy(() => import('./views/Routes/Management/ResourceDefinition/ResourceDefinition'));
const Parameters_Mng = React.lazy(() => import('./views/Routes/Management/Parameters/Parameters'));
const Kibana = React.lazy(() => import('./views/Routes/Management/Kibana/Kibana'));
const Department = React.lazy(() => import('./views/Routes/Management/Department/Department'));
const CommunicationDefinitions_Mng = React.lazy(() => import('./views/Routes/Management/CommunicationDefinitions/CommunicationDefinitions'));
const CommunicationTemplates_Mng = React.lazy(() => import('./views/Routes/Management/CommunicationTemplates/CommunicationTemplates'));
const Notifications_Mng = React.lazy(() => import('./views/Routes/Management/Notifications/Notifications'));
const LivePreview = React.lazy(() => import('./views/Routes/LivePreview/LivePreview'));
const TransactionLogs_Mng = React.lazy(() => import('./views/Routes/Management/TransactionLogs/TransactionLogs'));
const ChangePassword = React.lazy(() => import('./views/Routes/CommonPages/ChangePassword/ChangePassword'));
const CustomerContract = React.lazy(() => import('./views/Routes/CustomerContract/CustomerContract'));
const CustomerContractServiceCatalog = React.lazy(() => import('./views/Routes/CustomerContractServiceCatalog/CustomerContractServiceCatalog'));
const PriorityDefinition = React.lazy(() => import('./views/Routes/PriorityDefinition/PriorityDefinition'));
const Request = React.lazy(() => import('./views/Routes/Request/Request'));
const RequestAssociation = React.lazy(() => import('./views/Routes/RequestAssociation/RequestAssociation'));
const RequestConversation = React.lazy(() => import('./views/Routes/RequestConversation/RequestConversation'));
const RequestTask = React.lazy(() => import('./views/Routes/RequestTask/RequestTask'));
const RequestTaskComment = React.lazy(() => import('./views/Routes/RequestTaskComment/RequestTaskComment'));
// const ServiceCatalog = React.lazy(() => import('./views/Routes/ServiceCatalog/ServiceCatalog'));
const ServiceCatalog = React.lazy(() => import('./views/Routes/ServiceCatalog/ServiceCatalogTree'));
const ServiceLevelAgreement = React.lazy(() => import('./views/Routes/ServiceLevelAgreement/ServiceLevelAgreement'));
const ServiceLevelAgreementServiceCatalog = React.lazy(() => import('./views/Routes/ServiceLevelAgreementServiceCatalog/ServiceLevelAgreementServiceCatalog'));
const Profile = React.lazy(() => import('./views/Routes/Profile/Profile'));
const RequestAllDetail = React.lazy(() => import('./views/Routes/RequestAllDetail/RequestAllDetail'));
const ProblemAllDetail = React.lazy(() => import('./views/Routes/ProblemAllDetail/ProblemAllDetail'));
const MyRequest = React.lazy(() => import('./views/Routes/MyRequest/MyRequest'));
const MyTask = React.lazy(() => import('./views/Routes/MyTask/MyTask'));
const CreateRequest = React.lazy(() => import('./views/Routes/CreateRequest/CreateRequest'));
const PartnerRequestDashboard = React.lazy(() => import('./views/Routes/PartnerDashboard/PartnerRequestDashboard'));
const PartnerRequestTaskDashboard = React.lazy(() => import('./views/Routes/PartnerDashboard/PartnerRequestTaskDashboard'));
const RequestTaskCalendar = React.lazy(() => import('./views/Routes/RequestTaskCalendar/RequestTaskCalendar'));
const RequestTaskCalendarAdmin = React.lazy(() => import('./views/Routes/RequestTaskCalendar/RequestTaskCalendarAdmin'));
const GroupDefinition = React.lazy(() => import('./views/Routes/GroupDefinition/GroupDefinition'));
const GroupUser = React.lazy(() => import('./views/Routes/GroupUser/GroupUser'));
const KnowledgeBaseArticle = React.lazy(() => import('./views/Routes/KnowledgeBaseArticle/KnowledgeBaseArticle'));
const KnowledgeBaseArticleComment = React.lazy(() => import('./views/Routes/KnowledgeBaseArticleComment/KnowledgeBaseArticleComment'));
const KnowledgeBaseArticleKeyword = React.lazy(() => import('./views/Routes/KnowledgeBaseArticleKeyword/KnowledgeBaseArticleKeyword'));
const KnowledgeBaseArticleUserLike = React.lazy(() => import('./views/Routes/KnowledgeBaseArticleUserLike/KnowledgeBaseArticleUserLike'));
const KnowledgeBaseKeyword = React.lazy(() => import('./views/Routes/KnowledgeBaseKeyword/KnowledgeBaseKeyword'));
//const KnowledgeBaseTopic = React.lazy(() => import('./views/Routes/KnowledgeBaseTopic/KnowledgeBaseTopic'));
const KnowledgeBaseTopic = React.lazy(() => import('./views/Routes/KnowledgeBaseTopic/KnowledgeBaseTopicTree'));
const KnowledgeBaseArticleCustomer = React.lazy(() => import('./views/Routes/KnowledgeBaseArticleCustomer/KnowledgeBaseArticleCustomer'));
const KnowledgeBaseHelpfulArticle = React.lazy(() => import('./views/Routes/KnowledgeBaseHelpfulArticle/KnowledgeBaseHelpfulArticle'));
const KnowledgeBaseArticleApprove = React.lazy(() => import('./views/Routes/KnowledgeBaseArticleApprove/KnowledgeBaseArticleApprove'));
const Worklog = React.lazy(() => import('./views/Routes/Worklog/Worklog'));

var PageInfos = {
    // Dashboard: {
    //     name: getLocalizedText("DASHBOARD"),
    //     url: `/dashboard`,
    //     component: Dashboard,
    //     resourceCode: 'MainPage',
    //     icon: 'icon-speedometer',
    // },
    Menu_Dasboard: {
        name: getLocalizedText("PARTNER_DASHBOARD"),
        resourceCode: 'DashboardPages',
        icon: 'icon-speedometer',
        defaultOpen: true
    },
    Request_Dashboard: {
        name: getLocalizedText("REQUEST_STATISTICS"),
        url: `/partnerRequestDashboard`,
        component: PartnerRequestDashboard,
        resourceCode: 'Partner_RequestDashboard_Res',
        parentResourceCode: 'DashboardPages',
        icon: 'fa fa-bar-chart',
    },
    RequestTask_Dashboard: {
        name: getLocalizedText("REQUEST_TASK_STATISTICS"),
        url: `/partnerRequestTaskDashboard`,
        component: PartnerRequestTaskDashboard,
        resourceCode: 'Partner_RequestTaskDashboard_Res',
        parentResourceCode: 'DashboardPages',
        icon: 'fa fa-area-chart',
    },
    // PartnerDashboard: {
    //     name: getLocalizedText("PARTNER_DASHBOARD"),
    //     url: `/partnerdashboard`,
    //     component: PartnerDashboard,
    //     resourceCode: 'PartnerDashboard_Res',
    //     parentResourceCode:'DashboardPages',
    //     icon: 'fas fa-chart-pie',
    // },
    Title_Admin: {
        title: true,
        name: getLocalizedText("TITLE_ADMIN"),
        // resourceCode: 'AdminPages'
    },
    
    Menu_Admin: {
        name: getLocalizedText("ADMINISTRATION"),
        resourceCode: 'AdminPages',
        icon: 'cui-cog',
        defaultOpen: false
    },
    Tenant: {
        name: getLocalizedText('TENANTS'),
        url: '/tenant',
        component: Tenant,
        parentResourceCode: 'AdminPages',
        resourceCode: 'Tenant_Res',
        icon: 'icon-book-open'
    },
    // Company_Mng: {
    //     name: getLocalizedText("COMPANIES"),
    //     url: '/company',
    //     component: Company_Mng,
    //     parentResourceCode: 'AdminPages',
    //     resourceCode: 'CoreCompany_Res',
    //     icon: 'icon-grid',
    // },
    Department: {
        name: getLocalizedText("DEPARTMENTS_TITLE"),
        url: '/department',
        component: Department,
        parentResourceCode: 'AdminPages',
        resourceCode: 'CoreDepartment_Res',
        icon: 'icon-puzzle',
    },
    AuthTemplate_Mng: {
        name: getLocalizedText("AUTH_TEMPLATES"),
        url: '/authTemplates',
        component: AuthTemplates,
        parentResourceCode: 'AdminPages',
        resourceCode: 'AuthTemplates',
        icon: 'icon-docs',
    },
    Users_Mng: {
        name: getLocalizedText("USERS"),
        url: '/users',
        component: Users_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'Users',
        icon: 'icon-people',
    },
    ResourceDefinition_Mng: {
        name: getLocalizedText("RESOURCE_DEFINITION"),
        url: '/resource-definitions',
        component: ResourceDefinition_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'ResourceDefinition',
        icon: 'icon-layers',
    },
    Parameters_Mng: {
        name: getLocalizedText("PARAMETERS"),
        url: '/parameters',
        component: Parameters_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'SystemParameters',
        icon: 'icon-list',
    },
    Menu_Communications: {
        name: getLocalizedText('COMMUNICATION_SETTINGS_LABEL'),
        resourceCode: 'Communication',
        parentResourceCode: 'AdminPages',
        icon: 'icon-feed',
    },
    CommunicationDefinitions_Mng: {
        name: getLocalizedText('COMMUNICATION_DEFINITIONS'),
        url: '/communicationDefinitions',
        component: CommunicationDefinitions_Mng,
        parentResourceCode: 'Communication',
        resourceCode: 'CommunicationDefinitions_tab',
        icon: 'icon-note',
    },
    CommunicationTemplates_Mng: {
        name: getLocalizedText('COMMUNICATION_TEMPLATES'),
        url: '/communicationTemplates',
        component: CommunicationTemplates_Mng,
        parentResourceCode: 'Communication',
        resourceCode: 'CommunicationTemplates_tab',
        icon: 'icon-envelope',
    },
    // SearchMonitor_Mng: {
    //     name: getLocalizedText('SEARCH_MONITOR_LABEL'),
    //     url: '/kibana',
    //     component: Kibana,
    //     parentResourceCode: 'AdminPages',
    //     resourceCode: 'CoreSearchMonitor_Res',
    //     icon: 'icon-magnifier',
    // },
    AuditLogs_Mng: {
        name: getLocalizedText('AUDIT_LOGS'),
        url: '/transactionLogs',
        component: TransactionLogs_Mng,
        parentResourceCode: 'AdminPages',
        resourceCode: 'TransactionLogs_Res',
        icon: 'icon-book-open',
    },
    Notifications: {
        name: getLocalizedText("NOTIFICATIONS"),
        url: '/notifications/:id?',
        component: Notifications_Mng,
        resourceCode: 'Notifications',
        parentResourceCode: 'AdminPages',
        icon: 'icon-badge',
        showInSidebar: false,
    },
    // LivePreview: {
    //     name: getLocalizedText("LIVE_PREVIEW_LABEL"),
    //     url: '/livePreview',
    //     component: LivePreview,
    //     resourceCode: 'TestResource',
    //     icon: 'icon-eye',
    //     badge: { variant: 'info', text: 'NEW' }
    // },
    ChangePassword: {
        name: getLocalizedText('CHANGE_PASSWORD_LABEL'),
        url: '/changePassword',
        component: ChangePassword,
        resourceCode: 'ChangePassword_Res',
        showInSidebar: false,
        showInProfileSidebar: true,
        icon: 'icon-lock',
    },
    Menu_ServiceDesk: {
        name: getLocalizedText("MENU_SERVICE_DESK"),
        resourceCode: 'ServiceDeskPages',
        icon: 'cui-screen-desktop',
        defaultOpen: true
    },
    Menu_KnowledgeBaseGeneral: {
        name: getLocalizedText("MENU_SD_KNOWLEDGE_BASE"),
        resourceCode: 'GeneralKnowledgeBasePages',
        parentResourceCode: 'ServiceDeskPages',
        icon: 'fa fa-lightbulb-o',
        defaultOpen: true
    },
    KnowledgeBaseArticleCustomer: {
        name: getLocalizedText('MENU_SD_KNOWLEDGE_BASE'),
        url: '/knowledgeBase',
        component: KnowledgeBaseArticleCustomer,
        resourceCode: 'KnowledgeBase_Res',
        parentResourceCode: 'GeneralKnowledgeBasePages',
        icon: 'fa fa-lightbulb-o',
    },
    KnowledgeBaseHelpfulArticle: {
        name: getLocalizedText('SD_KNOWLEDGE_BASE_HELPFUL_ARTICLES_LABEL'),
        url: '/knowledgeBaseHelpfulArticles',
        component: KnowledgeBaseHelpfulArticle,
        resourceCode: 'KnowledgeBaseHelpfulArticle_Res',
        parentResourceCode: 'GeneralKnowledgeBasePages',
        icon: 'fa fa-thumbs-o-up',
    },
    CreateRequest: {
        name: getLocalizedText('SD_CREATE_REQUEST_LABEL'),
        url: '/createRequest',
        component: CreateRequest,
        resourceCode: 'CreateRequest_Res',
        parentResourceCode: 'ServiceDeskPages',
        icon: 'fa fa-ticket',
    },
    CustomerContract: {
        name: getLocalizedText('SD_CONTRACT_LABEL'),
        url: '/customerContract',
        component: CustomerContract,
        resourceCode: 'CustomerContract_Res',
        parentResourceCode: 'ServiceDeskPages',
        icon: 'fa fa-file-text',
    },
    // CustomerContractServiceCatalog: {
    //     name: getLocalizedText('SD_CUSTOMER_CONTRACT_SERVICE_CATALOG_LABEL'),
    //     url: '/customerContractServiceCatalog',
    //     component: CustomerContractServiceCatalog,
    //     resourceCode: 'CustomerContractServiceCatalog_Res',
    //     icon: 'fa fa-th-list',
    // },
    Menu_My_Pages: {
        name: getLocalizedText("MENU_SD_PERSONALIZED_PAGES"),
        resourceCode: 'ServiceDeskMyPages',
        icon: 'cui-user',
        parentResourceCode: 'ServiceDeskPages',
        defaultOpen: false
    },
    MyRequest: {
        name: getLocalizedText('SD_MY_REQUEST_LABEL'),
        url: '/MyRequest',
        component: MyRequest,
        resourceCode: 'MyRequest_Res',
        parentResourceCode: 'ServiceDeskMyPages',
        icon: 'fa fa-files-o',
    },
    MyTask: {
        name: getLocalizedText('SD_MY_TASK_LABEL'),
        url: '/myTask',
        component: MyTask,
        resourceCode: 'MyTask_Res',
        parentResourceCode: 'ServiceDeskMyPages',
        icon: 'cui-task',
    },
    RequestTaskCalendar: {
        name: getLocalizedText('SD_MY_REQUEST_TASK_CALENDAR_LABEL'),
        url: '/requestTaskCalendar',
        component: RequestTaskCalendar,
        resourceCode: 'RequestTaskCalendar_Res',
        parentResourceCode: 'ServiceDeskMyPages',
        icon: 'fa fa-calendar',
    },
    Menu_SD_Request: {
        name: getLocalizedText("MENU_SD_REQUEST"),
        resourceCode: 'ServiceDeskRequestPages',
        icon: 'cui-cog',
        parentResourceCode: 'ServiceDeskPages',
        defaultOpen: false
    },
    Request: {
        name: getLocalizedText('SD_REQUEST_LABEL'),
        url: '/request',
        component: Request,
        resourceCode: 'Request_Res',
        parentResourceCode: 'ServiceDeskRequestPages',
        icon: 'fa fa-files-o',
    },
    RequestAllDetail: {
        name: getLocalizedText('SD_REQUEST_DETAIL_LABEL'),
        url: '/requestDetail',
        component: RequestAllDetail,
        resourceCode: 'RequestAllDetail_Res',
        parentResourceCode: 'ServiceDeskRequestPages',
        icon: 'fa fa-info-circle',
    },
    RequestAssociation: {
        name: getLocalizedText('SD_REQUEST_ASSOCIATION_LABEL'),
        url: '/requestAssociation',
        component: RequestAssociation,
        resourceCode: 'RequestAssociation_Res',
        parentResourceCode: 'ServiceDeskRequestPages',
        icon: 'icon-energy',
    },
    RequestConversation: {
        name: getLocalizedText('SD_REQUEST_CONVERSATION_LABEL'),
        url: '/requestConversation',
        component: RequestConversation,
        resourceCode: 'RequestConversation_Res',
        parentResourceCode: 'ServiceDeskRequestPages',
        icon: 'fa fa-comments-o',
    },
    RequestTask: {
        name: getLocalizedText('SD_REQUEST_TASK_LABEL'),
        url: '/requestTask',
        component: RequestTask,
        resourceCode: 'RequestTask_Res',
        parentResourceCode: 'ServiceDeskRequestPages',
        icon: 'fa fa-tasks',
    },
    RequestTaskCalendarAdmin: {
        name: getLocalizedText('SD_REQUEST_TASK_CALENDAR_LABEL'),
        url: '/requestTaskCalendarAdmin',
        component: RequestTaskCalendarAdmin,
        resourceCode: 'RequestTaskCalendarAdmin_Res',
        parentResourceCode: 'ServiceDeskRequestPages',
        icon: 'fa fa-calendar',
    },
    ProblemAllDetail: {
        name: getLocalizedText('SD_PROBLEM_DETAIL_LABEL'),
        url: '/problemDetail',
        component: ProblemAllDetail,
        resourceCode: 'Problem_Res',
        parentResourceCode: 'ServiceDeskRequestPages',
        icon: 'fa fa-exclamation-circle',
    },
    Worklog:{
        name: getLocalizedText('SD_WORKLOG_LABEL'),
        url: '/worklog',
        component: Worklog,
        resourceCode: 'Worklog_Res',
        parentResourceCode: 'ServiceDeskRequestPages',
        icon: 'fa fa-tasks',
    },
    // RequestTaskComment: {
    //     name: getLocalizedText('SD_REQUEST_TASK_COMMENT_LABEL'),
    //     url: '/requestTaskComment',
    //     component: RequestTaskComment,
    //     resourceCode: 'RequestTaskComment_Res',
    //     icon: 'fa fa-commenting-o',
    // },
    Menu_SD_Settings: {
        name: getLocalizedText("MENU_SD_SETTINGS"),
        resourceCode: 'ServiceDeskSettingsPages',
        parentResourceCode: 'ServiceDeskPages',
        icon: 'cui-cog',
        defaultOpen: true
    },
    Title_SD_SETTINGS_DEFINITION: {
        title: true,
        name: getLocalizedText("MENU_SD_SETTINGS_DEFINITION"),
        parentResourceCode: 'ServiceDeskSettingsPages',
    },
    GroupDefinition: {
        name: getLocalizedText('SD_GROUP_DEFINITION_LABEL'),
        url: '/groupDefinition',
        component: GroupDefinition,
        resourceCode: 'GroupDefinition_Res',
        parentResourceCode: 'ServiceDeskSettingsPages',
        icon: 'fa fa-users',
    },
    ServiceCatalog: {
        name: getLocalizedText('SD_SERVICE_CATALOG_LABEL'),
        url: '/serviceCatalog',
        component: ServiceCatalog,
        resourceCode: 'ServiceCatalog_Res',
        parentResourceCode: 'ServiceDeskSettingsPages',
        icon: 'fa fa-book',
    },
    Menu_KnowledgeBase: {
        name: getLocalizedText("MENU_SD_KNOWLEDGE_BASE"),
        resourceCode: 'KnowledgeBasePages',
        parentResourceCode: 'ServiceDeskSettingsPages',
        icon: 'fa fa-lightbulb-o',
        defaultOpen: true
    },
    KnowledgeBaseArticle: {
        name: getLocalizedText('SD_KNOWLEDGE_BASE_ARTICLES_LABEL'),
        url: '/articles',
        component: KnowledgeBaseArticle,
        resourceCode: 'KnowledgeBaseArticle_Res',
        parentResourceCode: 'KnowledgeBasePages',
        icon: 'fa fa-book',
    },
    // NOTE: Authorization control was added at API Level. This page can be activated if necessary.
    //
    // KnowledgeBaseArticleApprove: {
    //     name: getLocalizedText('SD_KNOWLEDGE_BASE_ARTICLE_APPROVE_LABEL'),
    //     url: '/articlesApprove',
    //     component: KnowledgeBaseArticleApprove,
    //     resourceCode: 'KnowledgeBaseArticleSaveApprove_Res',
    //     parentResourceCode: 'KnowledgeBasePages',
    //     icon: 'fa fa-book',
    // },
    KnowledgeBaseArticleComment: {
        name: getLocalizedText('SD_KNOWLEDGE_BASE_ARTICLE_COMMENTS_LABEL'),
        url: '/articleComments',
        component: KnowledgeBaseArticleComment,
        resourceCode: 'KnowledgeBaseArticleComment_Res',
        parentResourceCode: 'KnowledgeBasePages',
        icon: 'fa fa-commenting-o',
    },
    KnowledgeBaseArticleUserLike: {
        name: getLocalizedText('SD_KNOWLEDGE_BASE_ARTICLE_USERLIKE_LABEL'),
        url: '/articleUserlike',
        component: KnowledgeBaseArticleUserLike,
        resourceCode: 'KnowledgeBaseArticleUserLike_Res',
        parentResourceCode: 'KnowledgeBasePages',
        icon: 'fa fa-thumbs-o-up',
    },
    KnowledgeBaseKeyword: {
        name: getLocalizedText('SD_KNOWLEDGE_BASE_KEYWORDS_LABEL'),
        url: '/knowledgeBaseKeyword',
        component: KnowledgeBaseKeyword,
        resourceCode: 'KnowledgeBaseKeyword_Res',
        parentResourceCode: 'KnowledgeBasePages',
        icon: 'fa fa-key',
    },
    KnowledgeBaseTopic: {
        name: getLocalizedText('SD_KNOWLEDGE_BASE_TOPICS_LABEL'),
        url: '/knowledgeBaseTopic',
        component: KnowledgeBaseTopic,
        resourceCode: 'KnowledgeBaseTopic_Res',
        parentResourceCode: 'KnowledgeBasePages',
        icon: 'fa  fa-lightbulb-o',
    },
    Title_SD_SETTINGS_CUSTOMIZATION: {
        title: true,
        name: getLocalizedText("MENU_SD_SETTINGS_CUSTOMIZATION"),
        parentResourceCode: 'ServiceDeskSettingsPages',
    },
    PriorityDefinition: {
        name: getLocalizedText('SD_PRIORITY_DEFINITION_LABEL'),
        url: '/priorityDefinition',
        component: PriorityDefinition,
        resourceCode: 'PriorityDefinition_Res',
        parentResourceCode: 'ServiceDeskSettingsPages',
        icon: 'fa fa-exclamation-triangle',
    },
    Title_SD_SETTINGS_AUTOMATION: {
        title: true,
        name: getLocalizedText("MENU_SD_SETTINGS_AUTOMATION"),
        parentResourceCode: 'ServiceDeskSettingsPages',
    },
    ServiceLevelAgreement: {
        name: getLocalizedText('SD_SLA_LABEL'),
        url: '/serviceLevelAgreement',
        component: ServiceLevelAgreement,
        resourceCode: 'ServiceLevelAgreement_Res',
        parentResourceCode: 'ServiceDeskSettingsPages',
        icon: 'fa fa-clock-o',
    },
    ServiceLevelAgreementServiceCatalog: {
        name: getLocalizedText('SD_SLA_SERVICE_CATALOG_LABEL'),
        url: '/serviceLevelAgreementServiceCatalog',
        component: ServiceLevelAgreementServiceCatalog,
        resourceCode: 'ServiceLevelAgreementServiceCatalog_Res',
        parentResourceCode: 'ServiceDeskSettingsPages',
        icon: 'fa fa-book',
    },
    Profile: {
        name: getLocalizedText('MY_PROFILE_LABEL'),
        url: '/profile',
        component: Profile,
        resourceCode: 'TestResource',//Profile_Res
        parentResourceCode: 'TestResource',
        icon: 'fa fa-user',
        showInProfileSidebar: true,
        //showInSidebar: cacheGet('selectedTenant') === null
    }
};

// Scheduler: {
//     name: "Scheduler",
//     url: '/scheduler',
//     component: Scheduler,
//     resourceCode: '',
//     icon: 'icon-clock',
// },

export var Pages = function () {
    for (var page in PageInfos) {
        PageInfos[page].key = page;

        if (PageInfos[page].url && PageInfos[page].path == null)
            PageInfos[page].path = `${PageInfos[page].url}`;
    }
    // let sortedPagesByName = Object.values(PageInfos).sort((a, b) =>
    // (a.name === 'Dashboard' || b.name === 'Dashboard') || a.name.localeCompare(b.name));
    return PageInfos;
}();

export var PagesAsNav = function () {
    var pages = _.values(_.cloneDeep(Pages)).filter(page => page.showInSidebar !== false);
    var toBeDeleted = [];
    for (var page of pages) {
        if (page.defaultOpen) {
            delete page.defaultOpen
            if (!page.url)
                page.url = '';
        }
        if (page.parentResourceCode) {

            var foundPage = pages.find(x => page.parentResourceCode && x.resourceCode == page.parentResourceCode);
            if (foundPage) {
                if (foundPage.children == null)
                    foundPage.children = [];

                foundPage.children.push(page);
                toBeDeleted.push(pages.findIndex(x => x.key == page.key));
            }
        }
    }

    toBeDeleted.sort((a, b) => a - b);
    toBeDeleted.reverse();

    for (var ind of toBeDeleted) {
        pages.splice(ind, 1);
    }
    return pages;
}();

export var PagesAsRoute = function () {
    var pages = _.values(_.cloneDeep(Pages));

    for (var page of pages) {
        if (page.url && page.parentResourceCode == null)
            page.exact = true;
    }
    return pages;
}();

export var PagesAsProfile = (function () {
    var pages = _.values(_.cloneDeep(Pages));
    // var toBeDeleted = [];
    // for (var page of pages) {
    // 	if (page.parentResourceCode) {
    // 		var foundPage = pages.find(x => page.parentResourceCode && x.resourceCode == page.parentResourceCode);
    // 		if (foundPage) {
    // 			if (foundPage.children == null) foundPage.children = [];
    // 			page.wrapper = {
    // 				attributes: {
    // 					style: {
    // 						marginLeft: 10
    // 					}
    // 				}
    // 			};
    // 			foundPage.children.push(page);
    // 			toBeDeleted.push(pages.findIndex(x => x.key == page.key));
    // 		}
    // 	}
    // }

    // toBeDeleted.sort((a, b) => a - b);
    // toBeDeleted.reverse();

    // for (var ind of toBeDeleted) {
    // 	pages.splice(ind, 1);
    // }
    pages = pages.filter(p => p.showInProfileSidebar == true);
    return pages;

})();